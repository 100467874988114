/**
 * Injects the Vue app instance into each Pinia store.
 *
 * This plugin allows the `app` instance to be accessed within Pinia stores
 * via `this.app`, enabling stores to use properties or methods from the app instance.
 *
 * @param {object} app - The Vue app instance to be injected into Pinia stores.
 * @returns {Function} A function to register the plugin with Pinia.
 */
export function piniaAppInjection(app) {
  return (context) => {
    context.store.app = app;
  };
}
