import { defineStore } from 'pinia';
import { getBrokerCurrentCoverages } from '@/apis/currentCoverages.js';
import { markSoldOpportunities } from '@/utils/featureFlags.js';
import dayjs from 'dayjs';
import { reusableProjectTypes, trackSegmentEvent } from '@watchtowerbenefits/es-utils-public';
import { setProductSoldState } from '@/apis/product.js';

const projectTypes = reusableProjectTypes();

export const useMarkSold = defineStore('markSold', {
  state: () => ({
    allCurrentCoverages: [],
    error: null,
    hasOfficeLocation: false,
    isLoaded: false,
    pendingMyClientCount: 0,
    productsReadyToBeMarkedSold: 0,
    productsReadyToBeMarkedSoldPastDue: 0,
    selectedValues: {
      carrierId: null,
      planDesignId: null,
    },
  }),
  actions: {
    /**
     * Retrieves the products ready to be marked sold from the API
     * and formats the data needed by the UI.
     *
     * @returns {Promise<void>}
     */
    async loadProductsReadyToBeMarkedSold() {
      if (!this.$ld.checkFlags(markSoldOpportunities)) {
        return;
      }

      this.resetProductsReadyToBeMarkedSold();

      try {
        const { current_coverage_view_data: coverages } = await getBrokerCurrentCoverages();
        const convertEventType = (eventType) => {
          switch (eventType) {
            case projectTypes.renewal:
              return 'Renewal';
            case projectTypes.goToMarket:
              return 'Full marketing';
            default:
              return eventType;
          }
        };

        coverages.forEach((coverage) => {
          const coverageWithEventType = {
            ...coverage,
            clientDelineation: coverage.is_my_client ? 'My clients' : 'All clients',
            event_type: convertEventType(coverage.event_type) ?? '',
            id: `${coverage.employer_id}-${coverage.product_name}`,
            renewal_date: coverage.renewal_date ?? '',
            status: coverage.status ?? '',
          };

          this.allCurrentCoverages.push(coverageWithEventType);

          if (coverageWithEventType.broker_name && !this.hasOfficeLocation) {
            this.hasOfficeLocation = true;
          }

          if (coverageWithEventType.status === 'Mark outcome') {
            this.productsReadyToBeMarkedSold += 1;

            coverageWithEventType.status = 'Pending mark sold';

            if (coverage.is_my_client) {
              this.pendingMyClientCount += 1;
            }

            if (dayjs(coverage.due_date).isBefore(dayjs())) {
              this.productsReadyToBeMarkedSoldPastDue += 1;
            }
          }
        });
      } catch {
        this.error = 'There was an error loading the products ready to be marked sold.';
      } finally {
        this.isLoaded = true;
      }
    },
    /**
     * Mark a quote as sold.
     *
     * @param {number} currentCoverageId - The unique id for the current coverage.
     * @param {number} projectId - The project id that the quote belongs to.
     */
    async markSold(currentCoverageId, projectId) {
      await setProductSoldState(this.selectedValues.planDesignId, true);
      const currentCoverageIndex = this.allCurrentCoverages
        .findIndex(({ id }) => id === currentCoverageId);

      this.allCurrentCoverages[currentCoverageIndex] = {
        ...this.allCurrentCoverages[currentCoverageIndex],
        status: '',
      };

      trackSegmentEvent('click mark sold confirm', {
        carrier_id: this.selectedValues.carrierId,
        project_id: projectId,
        product_type_ids: [this.selectedValues.planDesignId],
      });
    },
    /**
     * Mark the current product as not sold.
     *
     * @param {number} currentCoverageId - The unique id for the current coverage.
     * @param {number} projectId - The project id that the quote belongs to.
     */
    async undoMarkSold(currentCoverageId, projectId) {
      trackSegmentEvent('final_sold_modal_undo');

      await setProductSoldState(this.selectedValues.planDesignId, false);

      const currentCoverageIndex = this.allCurrentCoverages
        .findIndex(({ id }) => id === currentCoverageId);

      this.allCurrentCoverages[currentCoverageIndex] = {
        ...this.allCurrentCoverages[currentCoverageIndex],
        status: 'Pending mark sold',
      };

      trackSegmentEvent('click mark unsold', {
        carrier_id: this.selectedValues.carrierId,
        project_id: projectId,
        product_type_ids: [this.selectedValues.planDesignId],
      });
    },
    /**
     * Resets the products ready to be marked sold state.
     */
    resetProductsReadyToBeMarkedSold() {
      this.$reset();
    },
  },
});
