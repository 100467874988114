import {
  getCurrentUser,
  signIn as signInApi,
  signOut as signOutApi,
  getGoogleOauthSession,
} from '@/apis/account.js';
import LogRocket from 'logrocket';
// eslint-disable-next-line import/no-cycle
import { cookieAuthToken, setStorage } from '@/utils/services.js';
import { setUser as SentrySetUser } from '@sentry/vue';
import { canUseThirdParty } from '@/utils/general.js';

import { defineStore } from 'pinia';
import { useLaunchDarkly } from '@/stores/launchDarkly.js';
import {
  deleteCookie,
  formatDateAndTime,
  identifySegmentUser,
} from '@watchtowerbenefits/es-utils-public';

import { config } from '@/utils/config.js';

export const useUser = defineStore('user', {
  state: () => ({
    isLoaded: false,
    accessForbiddenError: '',
    user: {
      // create an object for components that need it.
      broker: {},
      roles: [],
    },
  }),
  getters: {
    /**
     * Determine if a user has one or more specific roles. The `roles` spread
     * syntax prop can take one or more roles (strings) and determine if any
     * of them are present in the user's roles array. This is an OR match;
     * `userHasRole('foo', 'bar')` will be true if either is present. If an AND
     * match is required, use `userHasRole('foo') && userHasRole('bar')`.
     *
     * @param {object} state
     * @param {object} state.user
     * @returns {boolean}
     */
    userHasRole: ({ user }) => (...roles) => (
      user.roles
        .map(({ name }) => name)
        .some((userRole) => (
          roles.some((r) => r.includes(userRole))))),
  },
  actions: {
    /**
     * Identify the user with launchdarkly, LogRocket, Sentry.
     */
    identifyUser() {
      const useAnalytics = config.analyticsEnabled(['production', 'staging', 'qa']);
      const launchDarkly = useLaunchDarkly();

      this.$ld.identify({
        hash: launchDarkly.configuration.options?.hash,
        newUser: launchDarkly.configuration.user,
      });

      if (useAnalytics || canUseThirdParty('sentry')) {
        SentrySetUser({
          department: this.user.department,
          email: this.user.email,
          'first name': this.user.first_name,
          id: this.user.id,
          'last login': formatDateAndTime(this.user.last_login_at),
          'last logout': formatDateAndTime(this.user.last_logout_at),
          'last name': this.user.last_name,
        });
      }

      if (useAnalytics || canUseThirdParty('logrocket')) {
        LogRocket.identify(this.user.id.toString(), {
          email: this.user.email,
          name: `${this.user.first_name} ${this.user.last_name}`,
          org_name: this.user.broker.name,
          roles: this.user.roles.map(({ name }) => name).join(', '),
        });
      }

      if (useAnalytics || canUseThirdParty('segment')) {
        identifySegmentUser(this.user);
      }
    },
    /**
     * GET the user info. Mutate the store data appropriately on success. Otherwise, throw an error.
     *
     */
    async loadUserInfo() {
      try {
        const { user } = await getCurrentUser();

        this.finishLoadingUser(user);
      } catch {
        this.accessForbiddenError = 'There was an error loading your user information.';
        this.signOut();
      }
    },
    /**
     * Sets the user info. Mutate the store data appropriately on success. Otherwise, throw an error..
     *
     * @param {object} user
     */
    async setSsoUserInfo(user) {
      try {
        await setStorage(user);

        this.finishLoadingUser(user);
      } catch {
        this.accessForbiddenError = 'There was an error loading your user information.';
        this.signOut();
      }
    },
    /**
     * Sign the user in, set localStorage, and load the user info.
     *
     * @param {string} email
     * @param {string} password
     */
    async signIn(email = '', password = '') {
      const { user } = await signInApi({ email, password });

      await setStorage(user);
      this.finishLoadingUser(user);
    },
    /**
     * Sign the user in based on a Google OAuth session code, set localStorage, and load the user info.
     *
     * @param {string} code
     */
    async signInWithGoogle(code) {
      const { user } = await getGoogleOauthSession(code);

      await this.setSsoUserInfo(user);
    },
    /**
     * Sign the user out, clear out the authentication in localStorage, cookies, and the store.
     */
    async signOut() {
      const launchDarkly = useLaunchDarkly();

      await signOutApi();

      window.localStorage.removeItem('isAuthorized');
      window.localStorage.removeItem('id');
      deleteCookie(cookieAuthToken);
      launchDarkly.resetLaunchDarkly();

      this.resetUser();
    },
    /**
     * Reset the user data in the store and delete the branding stylesheet.
     */
    resetUser() {
      this.$reset();

      const stylesheet = document.head.querySelector('.broker-branding-stylesheet');

      if (stylesheet) {
        stylesheet.parentNode.removeChild(stylesheet);
      }
    },
    /**
     * Set the user data in the store, load products ready to be marked sold, and identify the user, and set the branding.
     *
     * @param {object} user
     */
    finishLoadingUser(user) {
      this.user = user;

      this.identifyUser();

      this.isLoaded = true;
    },
  },
});
