import '@/assets/styles/main.scss';
import {
  init as SentryInit,
  configureScope,
  vueRouterInstrumentation,
  BrowserTracing,
} from '@sentry/vue';

import LogRocket from 'logrocket';
import { createApp } from 'vue';
import { createPinia } from 'pinia';
import { piniaAppInjection } from '@/utils/piniaAppInjection.js';

import LoadScript from 'vue-plugin-load-script';
import {
  activateAxios,
  addLogRocketURLToHeaders,
  setStorage,
} from '@/utils/services.js';

// Directives
import VueClickAway from 'vue3-click-away';

// Vue
// rff: globalSidebarNavigation this no-cycle can be removed.
// eslint-disable-next-line import/no-cycle
import router from '@/router.js';

// Stores
import { useCoreApi } from '@/stores/coreApi.js';
import { useLaunchDarkly } from '@/stores/launchDarkly.js';

// APIss
import { getHealthCheck } from '@/apis/healthCheck.js';

// Components:
// Element Plus
import {
  ElAlert,
  ElBreadcrumb,
  ElBreadcrumbItem,
  ElCheckbox,
  ElCheckboxGroup,
  ElCollapse,
  ElCollapseItem,
  ElDatePicker,
  ElDialog,
  ElDrawer,
  ElDropdown,
  ElDropdownMenu,
  ElDropdownItem,
  ElForm,
  ElFormItem,
  ElInput,
  ElLoading,
  ElMenu,
  ElMenuItem,
  ElMessage,
  ElOption,
  ElOptionGroup,
  ElPagination,
  ElPopover,
  ElProgress,
  ElRadio,
  ElRadioGroup,
  ElSelect,
  ElSubMenu,
  ElSwitch,
  ElTable,
  ElTableColumn,
  ElTabPane,
  ElTabs,
  ElTooltip,
  ElUpload,
} from 'element-plus';
// ui-components
import {
  AppAlert,
  AppApiTable,
  AppApiTableFilterSelect,
  AppApiTablePagination,
  AppApiTableThead,
  AppApiTableTr,
  AppAuthPageHeader,
  AppBadge,
  AppBrokerUserInviteTable,
  AppButton,
  AppCallout,
  AppComment,
  AppCommentInput,
  AppCommentsExport,
  AppCopyLinkContainer,
  AppCtaContactSupport,
  AppDateRangeInput,
  AppFormBuilder,
  AppFormList,
  AppIcon,
  AppInputWithOptions,
  AppInlineFormField,
  AppModalNotify,
  AppPagination as AppTablePagination,
  AppPasswordField,
  AppProductSelector,
  AppQuoteEditItem,
  AppQuoteEditsExport,
  AppRfpTable,
  AppSignIn,
  AppTable,
  AppTableTd,
  AppTableTr,
  AppTabsWrapper,
  AppThreeFlowWordmark,
  AppTooltip,
  AppUserAdmin,
  AppAuthForgotPassword,
  AppAuthResetPassword,
} from '@watchtowerbenefits/ui-components';

// Third-party tooling
import { logrocketPlugin } from '@/utils/piniaPlugins.js';
import { initializeSegment } from '@watchtowerbenefits/es-utils-public';
import {
  initializeLaunchDarkly,
  parseLaunchDarklyConfiguration,
} from '@/utils/launchDarklyConfig.js';

// Start app
import App from './App.vue';

// configureCompat({
//   MODE: 2, // Enable Vue 3 Compat Mode
//   TRANSITION_GROUP_ROOT: false,
//   COMPONENT_V_MODEL: false,
//   COMPONENT_ASYNC: false,
// });

const {
  VUE_APP_ANALYTICS: testAnalytics,
  VUE_APP_VERSION: appVersion,
  VUE_APP_ENV: appEnvironment,
} = import.meta.env;
const app = createApp(App);
const pinia = createPinia();

pinia.use(piniaAppInjection(app));

activateAxios(router);

// Used to as needed load the Google OAuth script.
app.use(LoadScript);

app.use(pinia);

// Register components:
// Element Plus
app.component('ElAlert', ElAlert);
app.component('ElBreadcrumb', ElBreadcrumb);
app.component('ElBreadcrumbItem', ElBreadcrumbItem);
app.component('ElCheckbox', ElCheckbox);
app.component('ElCheckboxGroup', ElCheckboxGroup);
app.component('ElCollapse', ElCollapse);
app.component('ElCollapseItem', ElCollapseItem);
app.component('ElDatePicker', ElDatePicker);
app.component('ElDialog', ElDialog);
app.component('ElDrawer', ElDrawer);
app.component('ElDropdown', ElDropdown);
app.component('ElDropdownMenu', ElDropdownMenu);
app.component('ElDropdownItem', ElDropdownItem);
app.component('ElForm', ElForm);
app.component('ElFormItem', ElFormItem);
app.component('ElSwitch', ElSwitch);
app.component('ElInput', ElInput);
app.component('ElMenu', ElMenu);
app.component('ElMenuItem', ElMenuItem);
app.component('ElMessage', ElMessage);
app.component('ElOption', ElOption);
app.component('ElOptionGroup', ElOptionGroup);
app.component('ElPagination', ElPagination);
app.component('ElPopover', ElPopover);
app.component('ElProgress', ElProgress);
app.component('ElRadio', ElRadio);
app.component('ElRadioGroup', ElRadioGroup);
app.component('ElSelect', ElSelect);
app.component('ElSubMenu', ElSubMenu);
app.component('ElTable', ElTable);
app.component('ElTableColumn', ElTableColumn);
app.component('ElTabPane', ElTabPane);
app.component('ElTabs', ElTabs);
app.component('ElTooltip', ElTooltip);
app.component('ElUpload', ElUpload);
app.directive('loading', ElLoading.directive);
app.config.globalProperties.$message = ElMessage;
app.provide('$message', ElMessage);
// ui-components
app.component('AppAlert', AppAlert);
app.component('AppApiTable', AppApiTable);
app.component('AppApiTableFilterSelect', AppApiTableFilterSelect);
app.component('AppApiTablePagination', AppApiTablePagination);
app.component('AppApiTableTd', AppTableTd);
app.component('AppApiTableThead', AppApiTableThead);
app.component('AppApiTableTr', AppApiTableTr);
app.component('AppAuthPageHeader', AppAuthPageHeader);
app.component('AppBadge', AppBadge);
app.component('AppBrokerUserInviteTable', AppBrokerUserInviteTable);
app.component('AppButton', AppButton);
app.component('AppCallout', AppCallout);
app.component('AppComment', AppComment);
app.component('AppCommentsExport', AppCommentsExport);
app.component('AppCommentInput', AppCommentInput);
app.component('AppCopyLinkContainer', AppCopyLinkContainer);
app.component('AppCtaContactSupport', AppCtaContactSupport);
app.component('AppDateRangeInput', AppDateRangeInput);
app.component('AppFormBuilder', AppFormBuilder);
app.component('AppFormList', AppFormList);
app.component('AppIcon', AppIcon);
app.component('AppInlineFormField', AppInlineFormField);
app.component('AppInputWithOptions', AppInputWithOptions);
app.component('AppModalNotify', AppModalNotify);
app.component('AppPasswordField', AppPasswordField);
app.component('AppProductSelector', AppProductSelector);
app.component('AppQuoteEditItem', AppQuoteEditItem);
app.component('AppQuoteEditsExport', AppQuoteEditsExport);
app.component('AppRfpTable', AppRfpTable);
app.component('AppSignIn', AppSignIn);
app.component('AppTable', AppTable);
app.component('AppTablePagination', AppTablePagination);
app.component('AppTableTd', AppTableTd);
app.component('AppTableTr', AppTableTr);
app.component('AppThreeFlowWordmark', AppThreeFlowWordmark);
app.component('AppTooltip', AppTooltip);
app.component('AppTabsWrapper', AppTabsWrapper);
app.component('AppUserAdmin', AppUserAdmin);
app.component('AppAuthForgotPassword', AppAuthForgotPassword);
app.component('AppAuthResetPassword', AppAuthResetPassword);

app.use(VueClickAway);

// Third-party tooling:
// Push sentry logs for non local environments.
if (['production', 'staging', 'qa', 'demo', 'features2'].includes(appEnvironment) || testAnalytics) {
  LogRocket.init('r2e0ub/broker-site', {
    console: {
      shouldAggregateConsoleErrors: true,
    },
    network: {
      requestSanitizer: (request) => {
        // if the request payload contains 'password'
        if (request?.body?.indexOf('password') !== -1) {
          // Scrub the request body
          // eslint-disable-next-line no-param-reassign
          request.body = null;
        }

        // return the request
        return request;
      },
    },
  });

  LogRocket.getSessionURL((sessionURL) => {
    // send the logrocket url to sentry so we can attach it to tickets
    configureScope((scope) => {
      scope.setExtra('sessionURL', sessionURL);
    });
    // When you generate the LogRocket session url, we call the Axios Interceptor to pass it on each network call
    addLogRocketURLToHeaders(sessionURL);
  });
  pinia.use(logrocketPlugin);

  initializeSegment(import.meta.env.VUE_APP_SEGMENT_TOKEN);

  if (appEnvironment !== 'demo') {
    SentryInit({
      app,
      dsn: 'https://ccf63cdc34944756912b71c5e881a02f@o97018.ingest.sentry.io/1254241',
      // ignore common non-breaking issues
      ignoreErrors: [
        /request failed with status code 401/i,
        /a network error occurred/i,
        /via a navigation guard/i,
        /non-error promise rejection captured/i,
        'TypeError: convertUTCDateToLocalDate',
        'TypeError: Cannot read properties of null (reading \'charAt\')',
        'NavigationDuplicated',
        'Failed to execute \'getComputedStyle\' on \'Window\': parameter 1 is not of type \'Element\'',
        'ResizeObserver loop limit exceeded',
        /scrollLeft/i,
        /scrollTop/i,
      ],
      integrations: [
        new BrowserTracing({
          routingInstrumentation: vueRouterInstrumentation(router),
          tracePropagationTargets: ['threeflow.com', /^\//],
          logErrors: true,
        }),
      ],
      environment: appEnvironment,
      release: `broker_ui-${appEnvironment}@${appVersion}`,
      // Stop 401 errors from being sent to sentry by returning null to discard the event entirely
      beforeSend: (event, { captureContext }) => (
        captureContext?.error?.response?.status === 401 ? null : event
      ),
    });
  }
}

// Use healthCheck, one of our few unauthenticated endpoints, to both check the health of the app and get the
// LaunchDarkly config data
let healthCheckCompleted = false;
let launchDarklyConfigData;

try {
  const response = await getHealthCheck();

  healthCheckCompleted = true;
  launchDarklyConfigData = parseLaunchDarklyConfiguration(response);
} catch {
  healthCheckCompleted = false;
  // If the health check fails, then we can't initialize LaunchDarkly because we don't have the clientSideId and
  // userInfo required to initialize LD in Secure Mode with an anonymous user but we still need featureFlag values,
  // so we pass "flagsStub" to initializeLaunchDarkly, thus still serving default flag values as defined in the
  // featureFlags file. A failed health check will result in various console errors which are not suppressed,
  // since the app will still run but with defaultFlag values (which is no bueno.)
  launchDarklyConfigData = { flagsStub: true };
} finally {
  const launchDarklyStore = useLaunchDarkly();
  const coreApiStore = useCoreApi();

  initializeLaunchDarkly(app, launchDarklyConfigData);

  pinia.use(({ store }) => {
    // eslint-disable-next-line no-param-reassign
    store.$ld = app.config.globalProperties.$ld;
  });

  coreApiStore.isHealthy = healthCheckCompleted;
  launchDarklyStore.configuration = launchDarklyConfigData;

  app.use(router);
  app.mount('#app');
}

export { app }; // Export the app instance

if (window.Cypress) {
  // Add setStorage function to window.Cypress as persistMockSignIn so that the mockSignIn command can
  // properly set localStorage and cookies
  window.Cypress.persistMockSignIn = setStorage;
}
