import { VueLd } from 'vue-ld'; // Plugin to integrate Launch Darkly into Vue apps
import { developmentFlagValues, defaultFlagValues } from '@/utils/featureFlags.js';

/**
 * Launch darkly configuration functions including initialization and identify calls for when a user changes.
 *
 * @exports launchDarklyConfig
 * @category Utils
 */

// Array of environments in which we want to stubFlag values and instead default to "developmentFlagValues"
const shouldStubFlags = ['development', 'test', 'ci'].includes(import.meta.env.VUE_APP_ENV)
  && !import.meta.env.VUE_APP_FEATURE_FLAGS;

/**
 * Adds checkFlags method to the global LaunchDarkly object to allow us to declare default flags in case
 * a flag cannot be found or a connection to LaunchDarkly cannot be established.
 *
 * @param {object} app - The Vue app instance to add the $ld object to.
 * @returns {Function}
 */
export const checkFlags = (app) => (flagName) => {
  if (window.Cypress?.cypressOverrideFlags?.[flagName] !== undefined) {
    return window.Cypress.cypressOverrideFlags[flagName];
  }

  const { ready, flags } = app.config.globalProperties.$ld;
  // Unable to call hasOwnProperty on a global variable, so object.keys used instead
  const ldFlagNameExists = flags && Object.keys(flags).includes(flagName);
  const defaultFlagNameExists = Object.prototype.hasOwnProperty.call(defaultFlagValues, flagName);

  if (shouldStubFlags || (ready && ldFlagNameExists)) {
    return flags[flagName];
  }

  // If a flag cannot be found or a connection to LaunchDarkly cannot be established and there is
  // no defaultFlagValue in the utility file, then the feature flag's value falls back to false.
  // Whether or not we want it to be true or false by default is a conversation for the team
  return defaultFlagNameExists ? defaultFlagValues[flagName] : false;
};

/**
 * Initializes Launch Darkly with an anonymous user and the clientSideId that corresponds with the
 * the current environment. Also passes any custom options to the Launch Darkly client.
 * Leverages dashHudsons Vue-Ld Vue plugin to interface with Launch Darkly: https://github.com/dashhudson/vue-ld.
 *
 * @param {object} app - The Vue app instance to add the $ld object to.
 * @param {object} app.clientSideId
 * @param {object} app.options
 * @param {object} app.user
 * @param {object} configuration
 * @param {string} configuration.clientSideId
 * @param {object} configuration.options
 * @param {object} configuration.user
 */
export const initializeLaunchDarkly = (app, { clientSideId, options, user }) => {
  app.use(VueLd, {
    clientSideId,
    user,
    options,
    // If flagsStub is provided, the ldClient will not be initialized and $ld.flags will be set to the provided stub (developmentFlagValues)
    flagsStub: shouldStubFlags ? developmentFlagValues : undefined,
  });
  // eslint-disable-next-line no-param-reassign
  app.config.globalProperties.$ld.checkFlags = checkFlags(app);
  app.provide('$ld', app.config.globalProperties.$ld);
};

/**
 * Parse the LD-CONFIGURATION header on every Core API response as a JSON object.
 *
 * @param {object} response
 * @param {object} response.headers
 * @returns {object}
 */
export const parseLaunchDarklyConfiguration = ({ headers = {} }) => JSON.parse(headers['ld-configuration']);
