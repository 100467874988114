import { createRouter, createWebHistory } from 'vue-router';
import { routes } from '@/routes.js';
// eslint-disable-next-line import/no-cycle
import { cookieAuthToken } from '@/utils/services.js';
import { appRoles } from '@/utils/appRoles.js';
import { waitUntil } from '@/utils/common.js';
import { useUser } from '@/stores/user.js';
import {
  deleteCookie,
  getCookie,
  trackSegmentPage,
} from '@watchtowerbenefits/es-utils-public';
import { config } from '@/utils/config.js';
// eslint-disable-next-line import/no-cycle
import { app } from './main.js';

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
  mode: 'history',
  /**
   * Checks for a saved position to scroll to otherwise starts at top of page.
   *
   * @param {object} to
   * @param {object} from
   * @param {object} savedPosition
   * @returns {object} The location to scroll to.
   */
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }

    return { top: 0 };
  },
});

export const beforeEach = (to) => {
  const isConfirmed = window.localStorage.getItem('isAuthorized') === 'true';
  const authCookie = getCookie(cookieAuthToken);

  // no authorization means they have been signed out at some point somewhere.
  if (
    (!isConfirmed || !authCookie)
    && !to?.meta?.noAuthRequired
  ) {
    // Set isAuthorized:false for SignInPage to show toast notification if the cookie has expired or is missing.
    if (!authCookie && isConfirmed) {
      window.localStorage.setItem('isAuthorized', false);
    }

    // Don't use a redirect query if the user is navigating to any of the home pages or isn't confirmed.
    if (!to?.meta?.noAuthRequired) {
      return {
        name: 'SignInPage',
        query: ['Insights', 'AllClients'].includes(to.name) || to.fullPath === '/' || !isConfirmed
          ? {}
          : { redirect: to.fullPath },
      };
    }
  }

  // at this point user is authenticated but is for some reason trying to access a page
  // that assumes they are not (sign in, register, forgot password). Redirect them to the home page.
  // note that sign out is a special case because we want to redirect to the sign in page and not the home page.
  if (
    to?.meta?.noAuthRequired
    && to?.meta?.redirectIfAuthenticated
    && isConfirmed
    && authCookie
    && !to?.params.signOut
  ) {
    // At this point the SSO user has tried to sign in while already signed in. So we delete the auth cookie
    // and allow them to go to /sso where a new authtoken will be generated.
    if (to.path === '/sso') {
      deleteCookie(cookieAuthToken);

      return true;
    }

    return { name: 'Home' };
  }

  if (to.name === 'MyClients' && app.config.globalProperties.$ld.flags.globalSidebarNavigation) {
    return { name: 'AllClients' };
  }

  return true;
};

export const afterEach = (to) => {
  document.title = to.meta.title
    ? `${to.meta.title} | ThreeFlow`
    : 'ThreeFlow';

  if (config.analyticsEnabled(['production', 'staging', 'qa'])) {
    trackSegmentPage(to.name, {
      name: to.name,
      path: to.path,
      url: window.location.href,
      // convert camel case to snake case for product team.
      ...Object.keys(to.params).reduce((params, param) => {
        const parsedKey = param.replace(/([A-Z])/g, '_$1').toLowerCase();

        return {
          ...params,
          [parsedKey]: to.params[param],
        };
      }, {}),
    });
  }
};

/**
 * Confirm the user has the appropriate roles to access the requested route.
 *
 * @param {object} to
 * @returns {boolean}
 */
export const beforeResolve = async (to) => {
  // only check for redirects if the route requires auth
  // i.e. don't bother on login page
  if (!to.meta?.noAuthRequired) {
    const userStore = useUser();

    await waitUntil(() => (userStore.isLoaded));

    const routingAccessError = 'A navigation error occurred because you do not have access to this content.';
    // If the route has explicit rolesRequired then we use them
    // Otherwise we traverse backwards through the route matches (from most to least specific)
    // looking for rolesRequired. When we find some, those are the roles we'll use.
    const routeRolesRequired = to.meta?.rolesRequired || [
      // use Set to de-dupe
      ...new Set(
        [].concat(
          // use reduce to locate the rolesRequired from the nearest matched route
          [
            ...to.matched,
          ].reverse()
            .reduce((prev, current) => (
              (prev.length && prev)
              || current.meta?.rolesRequired
              || []
            ), to),
        ).flat(),
      ),
    ];

    // user is navigating to Home
    if (to.fullPath === '/') {
      // User has Insights role, redirect to Insights
      // If user ONLY has BM go to Broker Management
      // Otherwise if user has CM/AP/PM redirect to AllClients
      if (userStore.userHasRole(appRoles.IS)) {
        return { name: 'Insights' };
      } if (userStore.userHasRole(appRoles.CM, appRoles.AP, appRoles.PM)) {
        if (app.config.globalProperties.$ld.flags.globalSidebarNavigation) {
          return { name: 'AllClients' };
        }
        return { name: 'MyClients' };
      } if (userStore.userHasRole(appRoles.BM)) {
        return { name: 'BrokerManagement' };
      }
      // failsafe for support. we should never get here
      userStore.accessForbiddenError = routingAccessError;
    } else if (routeRolesRequired.length
      && !userStore.userHasRole(routeRolesRequired.flat())) {
      // Lastly, the user is navigating to a page that requires roles that they don't have
      userStore.accessForbiddenError = routingAccessError;

      return { name: 'Home' };
    }

    return true;
  }

  return true;
};

router.beforeEach(beforeEach);
router.afterEach(afterEach);
router.beforeResolve(beforeResolve);

export default router;
